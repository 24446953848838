import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/SEO'

import errorStyles from '../css/404.module.css'

const NotFoundPage = () => (
	<div className={errorStyles.container}>
		<SEO title="404: Not found" />
		<p className={errorStyles.heading}>OEPSIE...</p>
		<p className={errorStyles.peetje}>
			Deze pagina werd niet gevonden. Keer terug naar de homepagina.
		</p>
		<div>
			<Link to="/" className={errorStyles.link}>
				TERUG NAAR HOME
			</Link>
		</div>
	</div>
)

export default NotFoundPage
